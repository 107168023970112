<template>
  <base-section id="thankyou">
    <!-- <base-heading title="404" /> -->

    <base-subheading
      class="primary--text"
      title="Thank You!"
    />

    <base-subheading
      space="1"
      title="We'll get in touch with you shortly."
    />

    <base-img
      :src="require('@/assets/logo-hi5magic.png')"
      class="mx-auto mb-12"
      contain
      height="250"
      width="250"
    />

    <!-- <div class="text-center">
      <base-btn :to="{ name: 'Home' }">
        Get me Out of Here
      </base-btn>
    </div> -->
  </base-section>
</template>

<script>
  export default {
    name: 'Thankyou',

    provide: {
      heading: { align: 'center' },
    },
  }
</script>
